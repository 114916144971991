import React from 'react';

const Footer = () => {
    return (
        <>
            <footer className={"footer"}>
                <p className={"footer-text"}>All rights reserved to Liron Daskal &copy; 2023</p>
            </footer>
            <style>{`
                .footer {
                   width: 100%;
                   height: 60px;
                   text-align: center;
                }
                .footer-text {
                    font-size: 14px;
                    font-family: 'Heebo', sans-serif;
                }
            `}</style>
        </>
    )
}

export default Footer;