import React, {ReactElement} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import WelcomePage from "./pages/WelcomePage";
import SurveyPage from "./pages/SurveyPage";
import ThankYouPage from "./pages/ThankYouPage";
import {AppContextComponent} from "./contexts/appContext";

const App = (): ReactElement => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={
                    <AppContextComponent>
                        <WelcomePage/>
                    </AppContextComponent>
                }/>
                <Route path="/survey" element={
                    <AppContextComponent>
                        <SurveyPage/>
                    </AppContextComponent>
                }/>
                <Route path="/thanks" element={
                    <AppContextComponent>
                        <ThankYouPage/>
                    </AppContextComponent>
                }/>
            </Routes>
        </Router>
    );
}

export default App;
