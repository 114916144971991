import React from 'react';
import welcomeBg from '../assets/images/welcome-bg.png';
import newLogo from '../assets/images/newLogo.png';
import kitchenLogo1 from '../assets/images/kitchenLogo1.png';
import kitchenLogo2 from '../assets/images/kitchenLogo2.jpeg';
import kitchenLogo3 from '../assets/images/kitchenLogo3.png';
import kitchenLogo4 from '../assets/images/kitchenLogo4.png';
import kitchenLogo5 from '../assets/images/kitchenLogo5.png';
import PrimaryButton from "../components/primaryButton";
import {useNavigate} from "react-router-dom";
import Footer from "../components/footer";
import Fade from 'react-reveal/Fade';

const WelcomePage: React.FC = () => {
    const navigate = useNavigate();
    const navigateToSurvey = () => {
        const timer = setTimeout(() => {
            navigate('/survey');

        }, 500);
        return () => clearTimeout(timer);
    }

    return (
        <div className="welcome-container" dir={"rtl"}>
            <div className="content-wrapper">
                <div className="logo-container">
                    <img src={newLogo} alt="Company Logo" className="company-logo"/>
                </div>
                <Fade bottom>
                    <div className={"creator-bubble"}>
                        <p className="creator-intro">
                            הי, אני לירון, מעצבת פנים עם נסיון של 15 שנים בתחום המטבחים. מתוך הנסיון הרב שצברתי, הבנתי
                            שקניית
                            המטבח יכולה להיות יעילה יותר.
                        </p>
                    </div>
                </Fade>
                <Fade bottom>
                    <div className={"creator-bubble"}>
                        <p className={"creator-intro"}>
                            בעזרת המערכת שבניתי ובשיתוף פעולה עם חברות המטבחים המובילות והנגרים
                            הכי מוכשרים בארץ, תוכלו לקבל בקלות את תוכנית המטבח הבסיסית שלכם ואת הצעות המחיר המותאמות
                            לתקציב
                            שלכם.
                        </p>
                        <h2 className={"subtext"}>
                            אז בואו נתחיל...
                        </h2>
                    </div>
                </Fade>
                <Fade bottom>
                    <div className="kitchen-logos">
                        <img src={kitchenLogo1} alt="Kitchen Company 1" className="kitchen-logo"/>
                        <img src={kitchenLogo2} alt="Kitchen Company 2" className="kitchen-logo"/>
                        <img src={kitchenLogo3} alt="Kitchen Company 3" className="kitchen-logo"/>
                        <img src={kitchenLogo4} alt="Kitchen Company 4" className="kitchen-logo"/>
                        <img src={kitchenLogo5} alt="Kitchen Company 5" className="kitchen-logo"/>
                    </div>
                </Fade>
                <Fade bottom>
                    <PrimaryButton text="צאו לדרך" onClick={navigateToSurvey}/>
                </Fade>
                <Footer/>
            </div>
            <style>{`
              .welcome-container {
                background: linear-gradient(to top, rgba(215, 213, 193, 0.6), rgba(215, 213, 193, 0)), url(${welcomeBg}) no-repeat bottom center;
                background-size: cover;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 20px;
                color: #6C7C89;
              }

              .content-wrapper {
                max-width: 800px;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
              }

              .logo-container {
                width: 100%;
              }
              
              .creator-bubble {
                background-color: #EDECE9;
                border-radius: 10px;
                padding: 20px;
                margin: 0 20px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                margin-bottom: 20px;
              }

              .company-logo {
                width: 100%;
                border-radius: 10px;
                max-width: 250px;
                height: auto;
              }

              .kitchen-logos {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 15px;
              }

              .kitchen-logo {
                width: 80px;
                height: auto;
                align-self: center;
              }

              .title {
                font-size: 28px;
                font-family: 'Heebo', sans-serif;
                text-align: center;
              }

              .subtext {
                font-size: 22px;
                font-family: 'Heebo', sans-serif;
                text-align: center;
              }

              .creator-intro {
                font-size: 16px;
                font-family: 'Heebo', sans-serif;
                opacity: 0.85;
              }

              @media only screen and (min-width: 601px) {
                .title {
                  font-size: 36px;
                }

                .subtext {
                  font-size: 28px;
                }
                 
                 .creator-intro {
                  font-size: 18px;
                }
              }
            `}</style>
        </div>
    );
};

export default WelcomePage;
