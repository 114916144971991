import React from 'react';
import welcomeBg from '../assets/images/welcome-bg.png';
import Footer from "../components/footer";
import newLogo from "../assets/images/newLogo.png";

const ThanksPage: React.FC = () => {
    return (
        <div className="thanks-container">
            <div className="content-wrapper">
                <div className="logo-container">
                    <img src={newLogo} alt="Company Logo" className="company-logo"/>
                </div>
                <div className="bubble">
                    <h1 className="title">וואלה שיחקת אותה! עכשיו אנחנו עובדים בשבילך</h1>
                    <p className="subtext">אנחנו ניצור איתך קשר בהקדם בנוגע להצעות המחיר הטובות ביותר</p>
                </div>
            </div>
            <Footer/>
            <style>{`
        .thanks-container {
          height: 100vh;
          background: linear-gradient(to top, rgba(215, 213, 193, 0.6), rgba(215, 213, 193, 0)), url(${welcomeBg}) no-repeat bottom center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          color: #6C7C89;
        }
        
        .logo-container {
            width: 100%;
        }
        
        .company-logo {
            width: 100%;
            border-radius: 10px;
            max-width: 250px;
            height: auto;
        }
        
        .bubble {
            background-color: #EDECE9;
            border-radius: 10px;
            padding: 20px;
            margin: 0 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            margin-bottom: 20px;
        }

        .content-wrapper {
          max-width: 800px;
          text-align: center;
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .title {
          font-size: 28px;
          font-family: 'Heebo', sans-serif;
          color: #6C7C89;
          margin-bottom: 10px;
          line-height: 1.3;
        }

        .subtext {
          font-size: 16px;
          font-family: 'Heebo', sans-serif;
          margin-bottom: 20px;
          padding: 0 10px; // Small padding for better line-breaking on mobile
        }

        @media only screen and (min-width: 601px) {
          .title {
            font-size: 36px;
            margin-bottom: 20px;
          }

          .subtext {
            font-size: 18px;
            padding: 0;
          }
        }
      `}</style>
        </div>
    );
};

export default ThanksPage;
