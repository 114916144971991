import React, {useState} from 'react';
import image from '../assets/images/button-bg.png';

export interface PrimaryButtonProps {
    text: string;
    onClick?: () => void;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({text, onClick}) => {
    const [isClicked, setIsClicked] = useState(false);
    return (
        <button className={`primary-btn ${isClicked ? 'clicked' : ''}`} onClick={() => {
            onClick?.();
            setIsClicked(true);
            setTimeout(() => setIsClicked(false), 300);
        }}>
            <img src={image} alt="Button background" className="image-bg"/>
            <span className="button-text">{text}</span>
            <style>{`
              .primary-btn {
                border: none;
                cursor: pointer;
                background: transparent;
                outline: none;
                padding: 0;
                position: relative;
                width: 400px; /* You can set this to your desired width */
                height: 200px; /* You can set this to your desired height */
              }
              
              .primary-btn.clicked .image-bg {
                  transform: scale(1.05);
                  transition: transform 300ms ease-out;
              }


              .image-bg {
                width: 100%;
                height: 100%;
                max-width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover; /* Ensure the image scales correctly */
              }

              .button-text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #EDECE9; /* Changed to a whitish color */
                font-size: 28px;
                font-weight: bold;
                font-family: 'Heebo', sans-serif;
                text-align: center;
                padding: 0 10px 20px 10px;
              }

              @media only screen and (max-width: 600px) {
                .primary-btn {
                  width: 300px; /* Adjust size for mobile */
                  height: 150px;
                }

                .button-text {
                  font-size: 24px; /* Adjust font size for mobile */
                }
              }
            `}</style>
        </button>
    );
};

export default PrimaryButton;
