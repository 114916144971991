import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import welcomeBg from '../assets/images/welcome-bg.png';
import PrimaryButton from "../components/primaryButton";
import {AppContext} from "../contexts/appContext";
import {useNavigate} from "react-router-dom";
import Answers from "../types/answers";
import Footer from "../components/footer";
import axios from "axios";

const SurveyPage: React.FC = () => {
    const navigate = useNavigate();
    const [file, setFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const {answers, setAnswers} = useContext(AppContext);
    const [errors, setErrors] = useState({
        budget: '',
        name: '',
        phone: '',
        email: '',
        fillAllFields: ''
    });

    const validateName = (name: string) => {
        if (!name.trim()) return "השם הוא שדה חובה";
        return "";
    };

    const validatePhone = (phone: string) => {
        const israeliPhonePattern = /^(0[2-9]|05\d)(?!911|1900|1004)\d{7}$/;
        if (!phone.trim()) return "Phone number is required.";
        if (!israeliPhonePattern.test(phone)) return "מספר טלפון ישראלי לא תקין";
        return "";
    };

    const validateEmail = (email: string) => {
        // Using a simple regex pattern for email validation
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!email.trim()) return "Email is required.";
        if (!emailPattern.test(email)) return "אימייל אינו תקין";
        return "";
    };

    useEffect(() => {
        if (answers?.name) {
            setErrors((prevState) => ({...prevState, name: validateName(answers.name)}));
        }
        if (answers?.phone) {
            setErrors((prevState) => ({...prevState, phone: validatePhone(answers.phone)}));
        }
        if (answers?.email) {
            setErrors((prevState) => ({...prevState, email: validateEmail(answers.email)}));
        }
        if (answers?.budget) {
            setErrors((prevState) => ({
                ...prevState,
                budget: answers.budget > 0 ? '' : 'התקציב חייב להיות גדול מ0'
            }));
        }
    }, [answers]);

    const validateForm = () => {
        const nonTextFields = [
            answers.likesCooking,
            answers.hoursInKitchen,
            answers.ovenSize,
            answers.sinkSize,
            answers.dishwasherSize,
            answers.kitchenIsland,
            answers.stylePreference,
            answers.materialPreference,
            answers.handlePreference,
        ];
        const isAnyNonTextEmpty = nonTextFields.some((x) => x === '');
        if (isAnyNonTextEmpty) {
            setErrors((prevState) => ({...prevState, fillAllFields: 'יש למלא את את כל השדות בבקשה'}));
            return false;
        } else {
            setErrors((prevState) => ({...prevState, fillAllFields: ''}));
        }
        return Object.values(errors).every((x) => x === '');
    }

    const onSubmit = async () => {
        let timer: NodeJS.Timeout;
        if (validateForm()) {
            const formData = new FormData();
            formData.append('file', file as Blob);
            formData.append('answers', JSON.stringify(answers));
            try {
                await axios.post('/api/submit', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                timer = setTimeout(() => {
                    navigate('/thanks');
                }, 500);
            } catch (e) {
                console.log(e);
                setErrors((prevState) => ({...prevState, fillAllFields: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר'}));
            }

        }
        return () => clearTimeout(timer);
    }


    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const selectedFile = acceptedFiles[0];
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result;
                console.log(binaryStr);
                setIsLoading(false);
            };
            reader.readAsArrayBuffer(selectedFile);
            setFile(selectedFile);
            setIsLoading(true);
        }
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});


    const handleAnswerChange = (key: string, value: string) => {
        setAnswers((prevState: Answers) => ({...prevState, [key]: value}));
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="survey-container" dir="rtl">
            <div className="form-wrapper">
                <h1>הדרך למטבח החלומות שלך מתחילה כאן</h1>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <h2>רק שאלון קצר ואנחנו נעשה בשבילכם את שאר העבודה</h2>
                <div className="question">
                    <p>האם אתם אוהבים לבשל?</p>
                    <label className="radio-label">
                        <input type="radio" name="likesCooking" value="כן" checked={answers.likesCooking === 'כן'}
                               onChange={(e) => handleAnswerChange('likesCooking', e.target.value)}/>
                        <span>כן</span>
                    </label>
                    <label className="radio-label">
                        <input type="radio" name="likesCooking" value="לא" checked={answers.likesCooking === 'לא'}
                               onChange={(e) => handleAnswerChange('likesCooking', e.target.value)}/>
                        <span>לא</span>
                    </label>
                </div>
                <div className="question">
                    <p>כמה שעות מהיום שלכם אתם מבלים במטבח?</p>
                    <input type="number" value={answers.hoursInKitchen} min={0}
                           max={24}
                           onChange={(e) => handleAnswerChange('hoursInKitchen', e.target.value)}/>
                </div>
                <div className="question">
                    <p>מה התקציב שלך?</p>
                    {errors.budget && <p className="error">{errors.budget}</p>}
                    <input type="number" value={answers?.budget > 0 ? answers.budget : ''} min={0} max={100000000}
                           onChange={(e) => handleAnswerChange('budget', e.target.value)}/>
                </div>
                <div className="question">
                    <p>מהו גודל התנור?</p>
                    <select defaultValue={""} value={answers.ovenSize}
                            onChange={(e) => handleAnswerChange('ovenSize', e.target.value)}>
                        <option value="60">60</option>
                        <option value="90">90</option>
                    </select>
                </div>
                <div className="question">
                    <p>מהו גודל הכיור?</p>
                    <select defaultValue={""} value={answers.sinkSize}
                            onChange={(e) => handleAnswerChange('sinkSize', e.target.value)}>
                        <option value="60">60</option>
                        <option value="60">70</option>
                        <option value="90">90</option>
                        <option value="כפול">כפול</option>
                    </select>
                </div>
                <div className="question">
                    <p>מהו גודל המדיח?</p>
                    <select defaultValue={""} value={answers.dishwasherSize}
                            onChange={(e) => handleAnswerChange('dishwasherSize', e.target.value)}>
                        <option value="45">45</option>
                        <option value="90">90</option>
                    </select>
                </div>
                <div className="question">
                    <p>האם יש לכם אי במטבח?</p>
                    <label className="radio-label">
                        <input type="radio" name="kitchenIsland" value="כן" checked={answers.kitchenIsland === 'כן'}
                               onChange={(e) => handleAnswerChange('kitchenIsland', e.target.value)}/>
                        <span>כן</span>
                    </label>
                    <label className="radio-label">
                        <input type="radio" name="kitchenIsland" value="לא" checked={answers.kitchenIsland === 'לא'}
                               onChange={(e) => handleAnswerChange('kitchenIsland', e.target.value)}/>
                        <span>לא</span>
                    </label>
                </div>
                <div className="question">
                    <p>אורך האי במטבח?</p>
                    <input type="number" value={answers.islandLength}
                           min={0}
                           disabled={answers.kitchenIsland === 'לא'}
                           max={100000000}
                           onChange={(e) => handleAnswerChange('islandLength', e.target.value)}/>
                </div>
                <div className="question">
                    <p>על איזה סגנון אתם עפים?</p>
                    <label className="radio-label">
                        <input type="radio" name="stylePreference" value="מודרני"
                               checked={answers.stylePreference === 'מודרני'}
                               onChange={(e) => handleAnswerChange('stylePreference', e.target.value)}/>
                        <span>מודרני</span>
                    </label>
                    <label className="radio-label">
                        <input type="radio" name="stylePreference" value="כפרי"
                               checked={answers.stylePreference === 'כפרי'}
                               onChange={(e) => handleAnswerChange('stylePreference', e.target.value)}/>
                        <span>כפרי</span>
                    </label>
                    <label className="radio-label">
                        <input type="radio" name="stylePreference" value="משולב"
                               checked={answers.stylePreference === 'משולב'}
                               onChange={(e) => handleAnswerChange('stylePreference', e.target.value)}/>
                        <span>משולב (חזית מסגרת היקפית)</span>
                    </label>
                </div>
                <div className="question">
                    <p>איזה חומר עושה לכם את זה?</p>
                    <select defaultValue={""} value={answers.materialPreference}
                            onChange={(e) => handleAnswerChange('materialPreference', e.target.value)}>
                        <option value="עץ">עץ</option>
                        <option value="פורמייקה">פורמייקה</option>
                        <option value="פורניר">פורניר</option>
                        <option value="נירוסטה">נירוסטה</option>
                        <option value="צבע בתנור">צבע בתנור</option>
                        <option value="עץ">עץ</option>
                        <option value="נאנו">נאנו</option>
                        <option value="אחר">אחר</option>
                    </select>
                </div>
                <div className="question">
                    <p>איך אתם אוהבים לפתוח ארונות?</p>
                    <label className="radio-label">
                        <input type="radio" name="handlePreference" value="ידיות"
                               checked={answers.handlePreference === 'ידיות'}
                               onChange={(e) => handleAnswerChange('handlePreference', e.target.value)}/>
                        <span>ידיות</span>
                    </label>
                    <label className="radio-label">
                        <input type="radio" name="handlePreference" value="מגרעות"
                               checked={answers.handlePreference === 'מגרעות'}
                               onChange={(e) => handleAnswerChange('handlePreference', e.target.value)}/>
                        <span>מגרעות</span>
                    </label>
                    <label className="radio-label">
                        <input type="radio" name="handlePreference" value="טאצ"
                               checked={answers.handlePreference === 'טאצ'}
                               onChange={(e) => handleAnswerChange('handlePreference', e.target.value)}/>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <span>טאצ'</span>
                    </label>
                </div>
                <div className="question">
                    <p>שם?</p>
                    {errors.name && <p className="error">{errors.name}</p>}
                    <input type="text" value={answers.name}
                           onChange={(e) => handleAnswerChange('name', e.target.value)}/>
                </div>
                <div className="question">
                    <p>טלפון?</p>
                    {errors.phone && <p className="error">{errors.phone}</p>}
                    <input type="tel" value={answers.phone}
                           onChange={(e) => handleAnswerChange('phone', e.target.value)}/>
                </div>
                <div className="question">
                    <p>אימייל?</p>
                    {errors.email && <p className="error">{errors.email}</p>}
                    <input type="email" value={answers.email}
                           onChange={(e) => handleAnswerChange('email', e.target.value)}/>
                </div>
                <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()}/>
                    {isLoading ? (
                        <p>File is loading...</p>
                    ) : file ? (
                        <p>File: {file.name}</p>
                    ) : isDragActive ? (
                        <p>יש לשחרר את הקבצים כאן...</p>
                    ) : (
                        <p>עכשיו נשאר רק להעלות את התוכנית שלכם</p>
                    )}
                </div>
                {errors.fillAllFields && <p className="error">{errors.fillAllFields}</p>}
                <PrimaryButton text="סיימתי!" onClick={onSubmit}/>
                <Footer/>
            </div>
            <style>{`
                .survey-container {
                    background: linear-gradient(to top, rgba(215, 213, 193, 0.6), rgba(215, 213, 193, 0)), url(${welcomeBg}) no-repeat bottom center;
                    background-size: cover;
                    min-height: 100vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    color: #6C7C89;
                    font-family: 'Heebo', sans-serif;
                    text-align: right;
                }
            
                .form-wrapper {
                    max-width: 100%;
                    margin: 0 auto;
                    background: rgba(255, 255, 255, 0.9);
                    padding: 30px;
                    border-radius: 10px;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            
                h1, h2 {
                    font-family: 'Heebo', sans-serif;
                    color: #6C7C89;
                    margin-bottom: 20px;
                    line-height: 1.3;
                }
            
                h1 {
                    font-size: 28px;
                }
            
                h2 {
                    font-size: 24px;
                }
            
                p {
                    font-size: 16px;
                    font-family: 'Heebo', sans-serif;
                    margin-bottom: 20px;
                    padding: 0 10px;
                }
                
                .error {
                    color: red;
                    font-size: 12px;
                    font-family: 'Heebo', sans-serif;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
            
                .radio-label {
                    display: inline-block;
                    margin-right: 10px;
                    cursor: pointer;
                    padding: 5px;
                    margin-top: 10px;
                    border: 2px solid #5A7B87;
                    background: #fff;
                    border-radius: 15px; // Making the radio labels more rounded
                    position: relative;
                    transition: background-color 0.3s;
                }
            
                .radio-label:hover {
                    background-color: #EDECE9; // Adding a hover effect
                }
            
                .radio-label input[type="radio"] {
                    display: none;
                }
            
                .radio-label input[type="radio"]:checked + span {
                    color: #5A7B87;
                }
            
                .radio-label span {
                    padding-left: 25px;
                    position: relative;
                }
            
                .radio-label span::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 15px;
                    height: 15px;
                    border: 2px solid #5A7B87;
                    border-radius: 50%;
                }
            
                .radio-label input[type="radio"]:checked + span::before {
                    background: #5A7B87;
                }
            
                input[type="text"], input[type="email"], input[type="number"], input[type="tel"], select {
                    padding: 10px;
                    border: 2px solid #5A7B87;
                    font-size: 16px;
                    color: #6C7C89;
                    margin-bottom: 10px;
                    border-radius: 15px;
                }
            
                input[type="radio"], input[type="number"], select {
                    margin-right: 5px;
                }
            
                .question {
                    margin-bottom: 25px;
                }
            
                .dropzone {
                    padding: 20px;
                    background: #EDECE9;
                    border: 2px dashed #5A7B87;
                    margin-bottom: 25px;
                    border-radius: 15px;
                }`
            }</style>
        </div>
    )
        ;
};

export default SurveyPage;
