import React, {createContext, useState, SetStateAction} from "react";
import Answers from "../types/answers";

export const AppContext = createContext({
    answers: {} as Answers,
    setAnswers: {} as React.Dispatch<SetStateAction<Answers>>
});
export const AppContextComponent = ({children}: {
    children: React.ReactNode;
}) => {
    const [answers, setAnswers] = useState({
        likesCooking: '',
        hoursInKitchen: '',
        ovenSize: '60',
        sinkSize: '60',
        dishwasherSize: '45',
        kitchenIsland: '',
        islandLength: '',
        stylePreference: '',
        budget: 0,
        materialPreference: 'עץ',
        handlePreference: '',
        name: '',
        phone: '',
        email: '',

    });

    return (
        <AppContext.Provider value={
            {
                answers,
                setAnswers
            }
        }>
            {children}
        </AppContext.Provider>
    );
}
